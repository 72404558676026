<template>
  <div class="page-account">
    <h2 class="p-title">{{ headerTitle }}</h2>
    <yos-tab-nav :list="tabNav" />
    <bread-crumbs :list="breadList" />
    <div class="p-card">
      <div class="card-title">账号信息</div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">手机号</span>
        <div class="right-wrapper">
          <yos-input
            v-model.trim="mobile"
            placeholder="(必填)请填写手机号"
          />
          <p>手机号最多11位数字</p>
        </div>
      </div>
      <div class="left-fixed-item fix" v-if="$route.params.id">
        <span class="left-fixed">密码</span>
        <div class="right-wrapper">
          <yos-button @click="onRestPassword">重置密码</yos-button>
        </div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">姓名</span>
        <div class="right-wrapper">
          <yos-input
            v-model.trim="name"
            placeholder="(必填)请填写姓名"
            :disabled="isAdmin"
          />
          <p>姓名最多5个字</p>
        </div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">状态</span>
        <div class="right-wrapper">
          <yos-radio name="status" :disabled="isAdmin" v-model="status" :value="1" label="正常" />
          <yos-radio name="status" :disabled="isAdmin" v-model="status" :value="0" label="禁用" />
        </div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">授予职责</span>
        <div class="right-wrapper right-box overflow-auto">
          <div class="roles" v-for="item in roles" :key="item.id">
            <yos-checkbox :disabled="isAdmin" v-model="roleIds[item.id]" :label="item.name" />
          </div>
        </div>
      </div>
      <div class="card-title no-top">管理公众号</div>
      <div class="p-search-bar fix">
        <span class="left-fixed">已勾选</span>
        <span><strong>{{ num }}</strong>个公众号</span>
        <span style="margin-left: 30px;">公众号：</span>
        <yos-input
          v-model.trim="keyword"
          placeholder="搜索公众号名称"
        />
        <yos-button class="yos-button-primary" @click="onSearch">搜索</yos-button>
      </div>
      <table
        cellspacing="0"
        class="m-table"
      >
        <thead>
          <tr>
            <th><yos-checkbox :disabled="isAdmin" v-model="allCheckeds" @change="onAllChange" />全选</th>
            <th>公众号</th>
            <!-- <th>授权状态</th>
            <th>总用户数</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in authorizers" :key="item.authorizerAppid">
            <td><yos-checkbox :disabled="isAdmin" v-model="checkeds[item.authorizerAppid]" @change="onAppidChange" /></td>
            <td>{{ item.nickName }}</td>
            <!-- <td>{{ item.status === 1 ? '正常' : '失效' }}</td>
            <td>{{ item.followedUsers }}</td> -->
          </tr>
        </tbody>
      </table>
      <yos-pager
        :baseUrl="baseUrl"
        :totalPage="totalPage"
        @change="onPageChange"
      />
      <bottom-options @save="onSave" />
    </div>
  </div>
</template>

<script>
import BreadCrumbs from '../../components/common/bread-crumbs'
import BottomOptions from '../../components/common/bottom-options'
import YosRadio from '../../components/form/yos-radio'
import YosPager from '../../components/other/yos-pager'
import YosTabNav from '../../components/other/tab-nav'
import YosCheckbox from '../../components/form/yos-checkbox.vue'
import { ref, computed, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDialog, useServer, useTabNav, usePage, useToast } from '../../hook'
import { addClass, getLocalUser, serialize } from '../../util'
import {
  getRoles,
  getAccount,
  addAccount,
  updateAccount,
  resetAccountPassword,
  getAuthorizers
} from '../../data'

export default {
  components: {
    BreadCrumbs,
    BottomOptions,
    YosRadio,
    YosPager,
    YosTabNav,
    YosCheckbox
  },
  setup() {
    const toast = useToast()
    const dialog = useDialog()
    const router = useRouter()
    const route = useRoute()
    const { id } = route.params
    const headerTitle = ref('创建账号')
    const nav = [{
      name: '账号列表',
      to: '/setting/accounts',
      active: true
    }, {
      name: '职责列表',
      to: '/setting/roles'
    }]
    const breadList = ref([{
      name: '账号列表',
      to: '/setting/accounts'
    }])
    const state = reactive({
      keyword: route.query.keyword || '',
      mobile: '',
      name: '',
      status: 1,
      roles: [],
      roleIds: {},
      authorizerAppids: '',
      authorizers: [],
      allCheckeds: false,
      checkeds: {},
      totalPage: 0,
      selecteds: {}
    })
    const readOnly = computed(() => status === 2)

    const isAdmin = computed(() => {
      return route.params.id && state.name === 'admin'
    })

    const num = computed(() => {
      return Object.keys(state.selecteds).filter(key => state.selecteds[key]).length
    })

    const { page, baseUrl } = usePage(`/setting/accounts/${id}`, { keyword: state.keyword })

    const success = ({ code, msg, mobile, password }) => {
      if (code === 201) {
        dialog({
          title: '创建帐号成功',
          content: `创建帐号成功，请复制帐号、密码并登录帐号：/n帐号：${mobile}/n密码：${password}/n登录账号成功后，请设置新密码。`,
          okText: '复制文本',
          cancelText: '',
          copyText: `帐号：${mobile} 密码：${password} 登录账号成功后，请设置新密码。`
        })
      } else {
        toast(msg)
      }
      router.back()
    }

    const onAppidChange = () => {
      const arr = Object.keys(state.checkeds)
      const checkedSize = arr.filter(key => state.checkeds[key]).length
      state.allCheckeds = checkedSize > 0 && checkedSize === arr.length
      arr.forEach(key => {
        state.selecteds[key] = state.checkeds[key]
      })
    }

    const onAllChange = () => {
      const checkeds = {}
      const selecteds = {...state.selecteds}
      Object.keys(state.checkeds).forEach(key => {
        checkeds[key] = state.allCheckeds
        selecteds[key] = state.allCheckeds
      })
      state.checkeds = checkeds
      state.selecteds = selecteds
    }

    const firstSuccess = () => {
      const checkeds = {}
      const selecteds = {}

      if (id ? (state.name && state.authorizers.length > 0) : state.authorizers.length > 0) {
        if (id && state.authorizerAppids) {
          state.authorizerAppids.split(',').forEach(item => {
            selecteds[item] = true
          })
        }
        state.authorizers.forEach(item => {
          const checked = state.authorizerAppids.indexOf(item.authorizerAppid) !== -1
          checkeds[item.authorizerAppid] = checked
          selecteds[item.authorizerAppid] = checked
        })

        state.checkeds = checkeds
        state.selecteds = selecteds
      }
    }

    const setAccountParams = useServer(getAccount, ({ name, mobile, status, roleIds, authorizerAppids }) => {
      state.name = name
      state.mobile = mobile
      state.status = status
      state.authorizerAppids = authorizerAppids
      roleIds.split(',').forEach(item => {
        state.roleIds[item] = true
      })
      firstSuccess()
      onAppidChange()
    })

    const setRolesParams = useServer(getRoles, ({ roles }) => {
      state.roles = roles
    })

    const setAuthorizersParams = useServer(getAuthorizers, ({ authorizers = [], totalPage }) => {
      const checkeds = {}
      state.authorizers = authorizers
      state.totalPage = totalPage

      if (Object.keys(state.selecteds).length === 0) {
        firstSuccess()
      } else {
        authorizers.forEach(item => {
          const checked = state.authorizerAppids.indexOf(item.authorizerAppid) !== -1
          checkeds[item.authorizerAppid] = state.selecteds[item.authorizerAppid] !== undefined ? state.selecteds[item.authorizerAppid] : checked
          state.selecteds[item.authorizerAppid] = checkeds[item.authorizerAppid]
        })
        state.checkeds = checkeds
      }

      onAppidChange()
    })

    const setResetPasswordParams = useServer(resetAccountPassword, ({ mobile, password }) => {
      dialog({
        title: '重置密码成功',
        content: `重置密码成功，请复制帐号、密码并登录帐号：/n帐号：${mobile}/n密码：${password}/n登录账号成功后，请设置新密码。`,
        okText: '复制文本',
        cancelText: '',
        copyText: `帐号：${mobile} 密码：${password} 登录账号成功后，请设置新密码。`
      })
    })

    const setUpdateRoleParams = useServer(updateAccount, success)

    const setAddRoleParams = useServer(addAccount, success)

    const onRestPassword = () => {
      dialog({
        title: '重置密码',
        content: '确定重置密码？',
        onOk: () => {
          setResetPasswordParams({ id })
        }
      })
    }

    const onSearch = () => {
      router.replace(serialize(baseUrl, { keyword: state.keyword, page: 1 }))
      setAuthorizersParams({ keyword: state.keyword, page: 1 })
    }

    const onSave = () => {
      const roleIds = Object.keys(state.roleIds).filter(key => state.roleIds[key]).join(',')
      const authorizerAppids = Object.keys(state.selecteds).filter(key => state.selecteds[key]).join(',')

      if (id) {
        setUpdateRoleParams({ id, name: state.name, mobile: state.mobile, status: state.status, roleIds, authorizerAppids })
      } else {
        setAddRoleParams({ name: state.name, mobile: state.mobile, status: state.status, roleIds, authorizerAppids })
      }
    }

    const onPageChange = (page) => {
      setAuthorizersParams({ keyword: state.keyword, page })
    }

    if (id >= 0) {
      setAccountParams({ id })
      headerTitle.value = '编辑账号'
    }

    breadList.value.push({ name: headerTitle.value })
    setRolesParams({ size: 1000 })
    setAuthorizersParams({ keyword: state.keyword, page })

    return {
      tabNav: useTabNav(nav),
      isAdmin,
      num,
      ...toRefs(state),
      page,
      baseUrl,
      readOnly,
      addClass,
      headerTitle,
      breadList,
      onRestPassword,
      onSave,
      onAllChange,
      onAppidChange,
      onPageChange,
      onSearch
    }
  }
}
</script>

<style lang="postcss">
.page-account {
  .yos-radio {
    margin-right: 10px;
  }

  .p-search-bar {
    padding-left: 10px;

    strong {
      color: #1aad19;
      margin: 0 5px;
    }
  }
}
</style>
